import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData'
import config from '../../config'
import { getSede, getToken } from '../../services/Usuario'

const MejorPuntajeFinales = () => {
    const [ListadoAlumnosP, setListadoAlumnosP] = useState([])  
    const [ListadoAlumnosB, setListadoAlumnosM] = useState([])  
    const [ListadoGradoP, setListadoGradoP] = useState([])  
    const [ListadoGradoB, setListadoGradoB] = useState([])  
    const [Listado3PrimGrado, setListado3PrimGrado] = useState([])  
    const [Loading, setLoading] = useState(false)  

   


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const listado = await GetData('estadisticas/mejoresalumnostodosperiodos/', null);
            setListadoAlumnosP(listado.primaria);
            setListadoAlumnosM(listado.secundaria);
            setListadoGradoP(listado.mejorgradop);
            setListadoGradoB(listado.mejorgradob);
//            setListado3PrimGrado(listado.primeros3prim);
            setLoading(false)
        }
        fetchData();

    }, [])
    
    const AbrirInforme = (op) => {
        const url_informe = config.API_URL_INFORMES;
        const url = url_informe + '/listadomejorpuntajes.php?op='+op+'&token='+getToken()+'&db='+getSede()
        window.open(url, "_blank");    
    }


  return (
    <>
        <section className='section dashboard'>
            <div className="card">
                <div className='card-header'>Mejores Promedios Finales Institución</div>                    

                <div className="card-body pb-0 mt-3">
                    
                    {Loading && 
                        <div className="progress mt-1 mb-2">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }      
                    <div className='card-title mt-1'>Primaria</div>  
                          
                    <div className="d-flex align-items-center mt-3 mb-3">
                        <h6>Mejores puntajes Finales a Nivel de Institución</h6>
                        <button type="button" className="btn btn-primary btn-sm ms-2 mt-0" onClick={() => AbrirInforme(1)}  >
                                    <i className="bi bi-person-plus"></i> Imprimir
                                </button>
                    </div>

                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Puesto</th>
                            <th scope="col">Grado</th>
                            <th scope="col">Alumno</th>
                            <th scope="col">Promedio</th>
                            <th scope="col">PRM</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoGradoP.map((grado, index) => 
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{grado.nombre_grado} - 0{grado.id_grupo}</td>                                                           
                                <td>{grado.nombres_apellidos_al}</td>
                                <td>{grado.promedio}</td>
                                <td>{grado.promedio_total2}</td>
                            </tr>
                        )}    
                        </tbody>
                    </table>
                    <div className="d-flex align-items-center mt-3 mb-3">
                    <h6>Mejores puntajes por grado</h6>
                    <button type="button" className="btn btn-primary btn-sm ms-2 mt-0" onClick={() => AbrirInforme(2)}  >
                                <i className="bi bi-person-plus"></i> Imprimir
                            </button>
                    </div>

                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Puesto</th>
                            <th scope="col">Grado</th>
                            <th scope="col">Alumno</th>
                            <th scope="col">Promedio</th>
                            <th scope="col">PRM</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoAlumnosP.map((grado, index) => 
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{grado.nombre_grado} - 0{grado.id_grupo}</td>                                                           
                                <td>{grado.nombres_apellidos_al}</td>
                                <td>{grado.promedio}</td>
                                <td>{grado.promedio_total2}</td>
                            </tr>
                        )}    
                        </tbody>
                    </table>

                    <div className='card-title '>Bachillerato</div>
                    <div className="d-flex align-items-center mt-3 mb-3">
                        <h6>Mejores puntajes Finales a Nivel de Institución</h6>
                        <button type="button" className="btn btn-primary btn-sm ms-2 mt-0" onClick={() => AbrirInforme(3)}  >
                                    <i className="bi bi-person-plus"></i> Imprimir
                                </button>
                    </div>
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Puesto</th>
                            <th scope="col">Grado</th>
                            <th scope="col">Alumno</th>
                            <th scope="col">Promedio</th>
                            <th scope="col">PRM</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoGradoB.map((grado, index) => 
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{grado.nombre_grado} - 0{grado.id_grupo}</td>                                                           
                                <td>{grado.nombres_apellidos_al}</td>
                                <td>{grado.promedio}</td>
                                <td>{grado.promedio_total2}</td>
                            </tr>
                        )}    
                        </tbody>
                        </table>
                        
                       
                        <div className="d-flex align-items-center mt-3 mb-3">
                            <h6 className="mb-0">Mejores puntajes por grado</h6>
                            <button 
                                type="button" 
                                className="btn btn-primary btn-sm ms-2 mt-0" 
                                onClick={() => AbrirInforme(4)}>
                                <i className="bi bi-person-plus"></i> Imprimir
                            </button>
                        </div>

                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Puesto</th>
                            <th scope="col">Grado</th>
                            <th scope="col">Alumno</th>
                            <th scope="col">Promedio</th>
                            <th scope="col">PRM</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoAlumnosB.map((grado, index) => 
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{grado.nombre_grado} - 0{grado.id_grupo}</td>                                                           
                                <td>{grado.nombres_apellidos_al}</td>
                                <td>{grado.promedio}</td>
                                <td>{grado.promedio_total2}</td>
                            </tr>
                        )}    
                        </tbody>
                    </table>

                </div>
            </div>
        </section>
    </>
    )
}

export default MejorPuntajeFinales