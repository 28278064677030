import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PostData } from "../services/PostData";

export const NivelacionConFechaInput = ({
  id,
  nivelacionValue,
  fechaValue,
  Grado,
  idAsignatura,
  id_matricula,
  usuario,
}) => {
  const [nivelacion, setNivelacion] = useState(nivelacionValue || "");
  const [fecha, setFecha] = useState(fechaValue || "");
  const [loading, setLoading] = useState(false);

  const handleNivelacionChange = (event) => {
    const { value } = event.target;
    const regex = /^\d*\.?\d*$/; // Validar números con punto decimal
    if (regex.test(value) || value === "") {
      setNivelacion(value);
    }
  };

  const handleFechaChange = (event) => {
    setFecha(event.target.value);
  };

  const guardarNivelacion = async () => {
    if (nivelacion < 0 || nivelacion > 5) {
      toast.error("La nota no puede ser mayor a 5.0");
      return;
    }

    const [grado, grupo] = Grado.split("-");
    const datos = {
      cod_grado: grado,
      id_grupo: grupo,
      id_asignatura: idAsignatura,
      id_matricula: id_matricula,
      nota: nivelacion,
    };

    setLoading(true);
    const result = await PostData(`calificaciones/guardarnivelacionadmin/`, datos, usuario);
    const responseJSON = result;

    if (responseJSON.error === false) {
      setLoading(false);
      toast.success("Nota guardada con éxito");
    } else {
      setLoading(false);
      toast.error("Error al guardar la nota");
    }
  };

  const guardarFecha = async () => {
    if (!fecha) {
      toast.error("La fecha no puede estar vacía.");
      return;
    }

    const [grado, grupo] = Grado.split("-");
    const datos = {
      cod_grado: grado,
      id_grupo: grupo,
      id_asignatura: idAsignatura,
      id_matricula: id_matricula,
      fecha_nivel: fecha,
    };

    setLoading(true);
    const result = await PostData(`calificaciones/guardarfechaniveadmin/`, datos, usuario);
    const responseJSON = result;

    if (responseJSON.error === false) {
      setLoading(false);
      toast.success("Fecha guardada con éxito");
    } else {
      setLoading(false);
      toast.error("Error al guardar la fecha");
    }
  };

  const borrarDatos = async () => {
    const [grado, grupo] = Grado.split("-");
    const datos = {
      cod_grado: grado,
      id_grupo: grupo,
      id_asignatura: idAsignatura,
      id_matricula: id_matricula,
    };

    setLoading(true);
    const result = await PostData(`calificaciones/borrarnivelacionadmin/`, datos, usuario);
    const responseJSON = result;

    if (responseJSON.error === false) {
      setLoading(false);
      setNivelacion("");
      setFecha("");
      toast.success("Nivelación y fecha eliminadas con éxito");
    } else {
      setLoading(false);
      toast.error("Error al eliminar la nivelación y fecha");
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (event.target.type === "text") {
        await guardarNivelacion();
      } else if (event.target.type === "date") {
        await guardarFecha();
      }

      // Navegación automática al siguiente campo
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      if (index < form.elements.length - 1) {
        form.elements[index + 1].focus();
      }
    }
  };

  return (
    <div className="d-flex align-items-center">
      {/* Input de nivelación */}
      <div className="">
            <input
                type="text"
                style={{ width: "60px", marginRight: "10px", textAlign: 'center' }}
                className="form-control form-control-sm estilocampo"
                value={nivelacion}
                onChange={handleNivelacionChange}
                onKeyDown={handleKeyDown}
                disabled={loading}
            />
        </div>
      {/* Input de fecha */}
      <div className="">
      <input
        type="date"
        style={{ width: "150px", marginRight: "10px" }}
        className="form-control form-control-sm estilocampo"
        value={fecha}
        onChange={handleFechaChange}
        onKeyDown={handleKeyDown}
        disabled={loading}
      />
        </div>
      {/* Botón de borrar */}
      <div>
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={<Tooltip id={`tooltip-top`}>Borrar Nota</Tooltip>}
        >
          <img
            src={`/assets/img/borrar.png`}
            className="icono_tabla"
            width={25}
            onClick={borrarDatos}
            style={{
              visibility: nivelacion !== "" ? "visible" : "hidden",
              cursor: "pointer",
            }}
            alt="Borrar Nota"
          />
        </OverlayTrigger>
      </div>

      {/* Indicador de carga */}
      {loading && (
        <div className="progress" style={{ marginTop: "10px", width: "100%" }}>
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            style={{ width: "100%" }}
          ></div>
        </div>
      )}
    </div>
  );
};
