import React, { useEffect, useState } from 'react'

import { GetData } from '../services/GetData';
import { SessionContext } from '../SessionContext';
import { useContext } from 'react';
import { PostData } from '../services/PostData';
import { NivelacionInput } from '../components/NivelacionInput';
import { FechaInput } from '../components/FechaInput';
import { ToastContainer } from 'react-toastify';
import { NivelacionConFechaInput } from '../components/NivelacionconFechaInput';

const NotasNivelacion = () => {    
    
    const [Grado, setGrado] = React.useState("");
    const [Loading, setLoading] = React.useState(false);
    const [LoadingAsig, setLoadingAsig] = React.useState(false);
    const [Alumnos, setAlumnos] = useState([]);
    const [promedios, setPromedios] = useState({});
    const [ListaGrados, setListaGrados] = useState([]);
    const [ListaAsignaturas, setListaAsignaturas] = useState([]);
    const [Asignatura, setAsignatura] = useState(0);
    const [UrlImpresion, setUrlImpresion] = useState('');
    const [openImpresion, setopenImpresion] = useState(false)    
    
    const { user: usuario } = useContext(SessionContext);
    const [Periodo, setPeriodo] = useState(usuario.periodo);
    const [isPeriodOpen, setisPeriodOpen] = useState(false);
    
    const ConsultarPlanilla = async () => {
      try {
        setLoading(true);    
    
        if (!Asignatura) {
          return () => {};
        }

        
        const [grado, grupo] = Grado.split('-');
        const datos = {
          cod_grado: grado,
          id_grupo: grupo,
          id_asignatura: Asignatura,
        };
    
        setAlumnos([]);
        const result = await GetData('matriculas/lisaniveladosgrado/' + grado + '/' + grupo + '/' + Asignatura + '/');
        const responseJSON = result;
    
        setUrlImpresion(process.env.REACT_APP_URL_INFORMES + 'planilla_calificaciones.php?grado=' + grado + '&grupo=' + grupo + '&asig=' + Asignatura + '&periodo=' + Periodo + '&token=' + usuario.token);
    
        if (responseJSON.error === false) {
            setAlumnos(responseJSON.alumnos);
            setLoading(false);
        } else {
          console.log("Error al cargar las asignaciones");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
      const AbrirPlanillaFinal = () => {
            const [grado, grupo] = Grado.split('-');

            setUrlImpresion(process.env.REACT_APP_URL_INFORMES + 'planilla_final.php?grado=' + grado + '&grupo=' + grupo + '&asig=' + Asignatura + '&token=' + usuario.token);
            setopenImpresion(true)
      }
      const AbrirPlanilla = () => {
            const [grado, grupo] = Grado.split('-');
            setUrlImpresion(process.env.REACT_APP_URL_INFORMES + 'planilla_calificaciones.php?grado=' + grado + '&grupo=' + grupo + '&asig=' + Asignatura + '&periodo=' + Periodo + '&token=' + usuario.token);
            setopenImpresion(true)
      }
 
      useEffect(() => {
            setAlumnos([])
            GetData("asignaciones/listgradosadmin/", usuario).then((result) => {
              let responseJSON = result;
              if (responseJSON.error === false) {
                  const datos = responseJSON.grados;
                  setListaGrados(datos)
                  setGrado(""); //setGrado(datos[0].cod_grado_grupo);
                  setAsignatura("")
              }
            })
       
      }, [usuario])

      useEffect(() => {
    
        if (Grado.length > 0){
            const [grado, grupo] = Grado.split('-');
            setAsignatura([]);
            setLoadingAsig(true)
            GetData("asignaciones/listasignagradogrupo/" + grado + '/' + grupo+'/', usuario).then((result) => {
              let responseJSON = result;
              if (responseJSON.error === false) {
                  const datos = responseJSON.asignaturas;
                  setListaAsignaturas(datos)
                  setAsignatura(datos[0].id_asignatura);
                  setLoadingAsig(false)
              }
            })
      }else
      return () => {}; // Función de limpieza vacía

    
      }, [Grado, usuario])
         

    
  return (
    <> 

        <section className='section dashboard'>
            <div className="card">
            <div className='card-header'>Nivelaciones Finales</div>    
            <div className="card-body pb-0">

                <div className="formulario">
                    <form method="post" >

                    <div className='row mt-3'>
                            <div className='col-md-2'>
                                <strong>Grado: </strong>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Grado} onChange={(e) => setGrado(e.target.value)} >
                                <option value="">Seleccionar</option>
                                {ListaGrados.map(grado => {
                                            return <option key={grado.cod_grado_grupo} value={grado.cod_grado_grupo}>
                                              {grado.nombre_grado} - 0{grado.id_grupo}
                                              </option>
                                    })}
                                </select> 
                            </div>                               
                            <div className='col-md-3'>
                                <strong>Asignaturas: </strong>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Asignatura} onChange={(e) => setAsignatura(e.target.value)} >
                                <option value="">Seleccionar</option>
                                {ListaAsignaturas.map(tipo => {
                                            return <option key={tipo.id_asignatura} value={tipo.id_asignatura}>
                                               {tipo.nombre_asignatura}
                                              </option>
                                    })}
                                </select> 
                                {LoadingAsig && 
                                  <div class="progress mt-3">
                                      <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>      
                                }      
                            </div>                               
                            <div className='col-md-3'>
                                <button type="button" className="btn btn-info btn-sm mt-4" onClick={ConsultarPlanilla} disabled={Grado==="" || Asignatura=== ""}>
                                        <i className="bi bi-search"></i>
                                </button>                              
                            </div>  
                            <div className='col-md-5' style={{marginTop: '25px'}}>
                                <span className='ml-4 label'>Grado:</span> <span className='texto'></span>  
                                <span className='ml-4 label'>N° Alumnos: </span> <span className='texto'>{Alumnos.length}</span>                                
                            </div>

                        </div>

                    </form>

                </div>

                {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                      <form method="post" >
                    <table class="table table-striped  mt-2">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nombre Alumno</th>
                            <th scope="col">Nota Fn</th>
                            <th scope="col">Nota Y Fecha Niv</th>
                            
                        </tr>
                        </thead>
                        <tbody>
                        {Alumnos.map((us, index) => 
                        <tr index={us.id_matricula}>
                            <th scope="row">{index+1}</th>
                            <td> <a href='#'  title='Ver Alumno' >{us.nombres_apellidos_al}</a></td>
                            <td>{us.nota_final}</td>
                            <td>
                {us.nota_final < 3 && (
                  <NivelacionConFechaInput
                    id={`input-${us.id_matricula}`}
                    nivelacionValue={us.nivelacion}
                    fechaValue={us.fecha_nivel}
                    Grado={Grado}
                    idAsignatura={Asignatura}
                    id_matricula={us.id_matricula}
                    usuario={usuario}
                  />
                )}
              </td>                           
                        </tr>
                        )}    
                        
                        </tbody>
                    </table>                    
                    </form>

            </div>           
          </div>
        </section>    

        <ToastContainer />
    </>
  )
}

export default NotasNivelacion