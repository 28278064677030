import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import { GetData } from '../../services/GetData';

const EstadisticaGradosFinal = () => {
    
    const [grados, setGrados] = useState([]);
    const [chartData, setChartData] = useState({
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        colors: ["#36B019", "#FF4560", "#FFC300", "#008FFB"],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Distribución de Estados por Grado",
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " estudiantes";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
    });
      
        useEffect(() => {
          // Llamar a la API
          GetData('asignaciones/todosgrados/', null).then ((result) =>{                     
              const gradosData = result.grados.map((grado) => {
                // Extraer valores sin porcentajes ni paréntesis
                const num_retirados_r = parseInt(grado.num_retirados_r);
                const num_desert_r = parseInt(grado.num_desert_r);
                const num_aprobados_r = parseInt(grado.num_aprobados_r);
                const num_reprobados_r = parseInt(grado.num_reprobados_r);
      
                return {
                  nombre_grado: grado.nombre_grado + ' - ' + grado.num_grupo,
                  num_retirados_r,
                  num_desert_r,
                  num_aprobados_r,
                  num_reprobados_r,
                };
              });
      
              setGrados(gradosData);

        // Procesar datos para el gráfico
        const categories = gradosData.map((grado) => grado.nombre_grado );
        const aprobados = gradosData.map((grado) => grado.num_aprobados_r);
        const reprobados = gradosData.map((grado) => grado.num_reprobados_r);
        const retirados = gradosData.map((grado) => grado.num_retirados_r);
        const desertados = gradosData.map((grado) => grado.num_desert_r);

        setChartData({
          series: [
            { name: "Aprobados", data: aprobados },
            { name: "Reprobados", data: reprobados },
            { name: "Retirados", data: retirados },
            { name: "Desertados", data: desertados },
          ],
          options: {
            ...chartData.options,
            xaxis: { categories },
          },
        });
      })
      .catch((error) => console.error("Error al obtener los datos:", error));

        }, []);

        
        return (
            <>
                  <section className='section dashboard'>
                    <div className="card">
                        <div className='card-header'>Estadísticas de Desempeño Académico por Grado</div>                    
                            <div className="card-body pb-0">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        
                                        <div class="alert alert-warning alert-dismissible fade show mt-3" role="alert">
                                             Visualiza la distribución de estados académicos (<strong>Aprobados, Reprobados, Retirados y Desertados</strong>) por grado y grupo. Este análisis permite identificar el rendimiento y la situación académica de los estudiantes en cada nivel educativo.
                                        </div>

                                    </div>
                                </div>
                                   <Chart options={chartData.options} series={chartData.series} type="bar"  height={`${grados.length * 30}px`}  />
                            </div>
                        </div>
                    </section>
            </>                
        )
}

export default EstadisticaGradosFinal