import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData'
import config from '../../config'
import { getSede, getToken } from '../../services/Usuario'

const AlumnosPerdieron = () => {
    const [ListadoEstudiantes, setListadoEstudiantes] = useState([])  
    const [NotasEstudiante, setNotasEstudiante] = useState([])  
    const [NumPerdidas, setNumPerdidas] = useState(false)  
    const [Estudiante, setEstudiante] = useState("")  
    const [Loading, setLoading] = useState(false)  

   
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const listado = await GetData('matriculas/estudiantesperdidos/', null);           
            setListadoEstudiantes(listado.alumnos);
//            setListado3PrimGrado(listado.primeros3prim);
            setLoading(false)
        }
        fetchData();

    }, [])
    
    const AbrirInforme = (op) => {
        const url_informe = config.API_URL_INFORMES;
        const url = url_informe + '/listado_alumnos_perdidos.php?token='+getToken()+'&db='+getSede()
        window.open(url, "_blank");    
    }

    const abrirNotasAlumno = (id_matricula, nombre) => {
        setEstudiante("")
        GetData('estadisticas/historinotasalumno/'+id_matricula+'/0/0').then ((result) =>{
          let responseJSON = result;
          
          if (responseJSON.error === false){        
              setNotasEstudiante(result.notas)
              setNumPerdidas(result.numperd)
              setEstudiante(nombre)
              const modal = document.getElementById('modalNotas');
    
              // Verificar si el modal existe antes de intentar abrirlo
              if (modal) {
                // Usar el método modal() para abrir el modal
                const modalInstancia = new window.bootstrap.Modal(modal);
                modalInstancia.show();
              }
          }
      })
    
    
       
      }
    

  return (
    <>
        <section className='section dashboard'>
            <div className="card">
                <div className='card-header'>Alumnos con Año Perdido</div>                    

                <div className="card-body pb-0 mt-3">
                    
                    {Loading && 
                        <div className="progress mt-1 mb-2">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }      
                  
                          
                    <div className="d-flex align-items-center mt-3 mb-3">
                        <h6>Listado de Alumnos con 3 o más asignaturas Perdidas</h6>
                        <button type="button" className="btn btn-primary btn-sm ms-2 mt-0" onClick={() => AbrirInforme(1)}  >
                                    <i className="bi bi-arrow-down-short"></i> Exportar a Excel
                                </button>
                    </div>
                    <div className='row'>
                        <div className='alert alert-warning alert-dismissible fade show'>
                        Esta vista muestra un listado de estudiantes que no lograron superar el año académico debido a que presentan promedios deficientes en 3 o más asignaturas. Los datos reflejan el promedio acumulado de los cuatro periodos académicos, destacando las áreas en las que los estudiantes tienen mayores dificultades. La información incluye el grado y grupo al que pertenecen, junto con las asignaturas reprobadas, facilitando un análisis detallado para la planificación de medidas educativas correctivas o alternativas.
                        </div>
                    </div>

                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">N°</th>
                            <th scope="col">Grado</th>
                            <th scope="col">Alumno</th>
                            <th scope="col">Perdidas</th>
                            <th scope="col">Asignaturas</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoEstudiantes.map((grado, index) => 
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{grado.nombre_grado} - 0{grado.id_grupo}</td>                                                           
                                <td onClick={()=>abrirNotasAlumno(grado.id_matricula, grado.nombres_apellidos_al)} className='cursor'>{grado.nombres_apellidos_al}</td>
                                <td align='center'>{grado.asignaturas_perdidas}</td>
                                <td dangerouslySetInnerHTML={{ __html: grado.asignaturas }}></td>
                            </tr>
                        )}
    
                        </tbody>
                    </table>
                   

                </div>
            </div>
        </section>



        <div className="modal fade" id="modalNotas" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-pencil-square"></i> Notas Alumno</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

              <div className="card">
                
                <div className="card-body cnotas">
                    <h4 className="card-title">Calificaciones </h4>
                    <div >
                        <strong>Alumno: </strong> {Estudiante}
                    </div>    
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Docente</th>
                            <th scope="col">Asignatura</th>
                            <th scope="col">P. 1</th>
                            <th scope="col">P. 2</th>
                            <th scope="col">P. 3</th>
                            <th scope="col">P. 4</th>
                            <th scope="col">Prom</th>
                        </tr>
                        </thead>
                        <tbody>
                            {NotasEstudiante.map((us, index) => (
                                <tr key={us.id_asignatura}>
                                <td>{us.docente}</td>
                                <td><a href='#' data='Ver Actividades' >{us.asignatura}</a></td>
                                {us.notas.map((notaPeriodo, indexPeriodo) => (
                                        <td key={indexPeriodo}>
                                        {notaPeriodo.length > 0 && notaPeriodo[0].nota_fn < 3 ? (
                                            <span className='nota_perdida'>{parseFloat(notaPeriodo[0].nota_fn).toFixed(1)}</span>
                                        ) : (
                                            notaPeriodo.length > 0 ? parseFloat(notaPeriodo[0].nota_fn).toFixed(1) : ''
                                        )}
                                        </td>
                                    ))}
                                <td>
                                  {us.promedio < 3 ?  <span className='nota_perdida'>{parseFloat(us.promedio).toFixed(1)}</span> 
                                    :
                                    <span>{parseFloat(us.promedio).toFixed(1)}</span>  
                                }
                                    
                                </td>
                                </tr>
                            ))}
                            </tbody>
                    </table>                    
                    <div className='row'>
                        <div className='col-md-3'>
                            <span className='label'>Num. Perdidas: </span> {NumPerdidas}
                        </div>
                    </div>
                    </div>

                </div>


                </div>
                </div>
        </div>
        </div>
    </>
    )
}

export default AlumnosPerdieron