export const getBadge = (estado) => {
    const estadoNormalizado = estado.toUpperCase().trim(); // Asegúrate de normalizar los valores
    switch (estadoNormalizado) {
      case "APROBÓ":
        return <span className="badge badge-custom-green">Aprobó</span>;
      case "REPROBÓ":
        return <span className="badge bg-danger">Reprobó</span>;
      case "ACTIVO":
        return <span className="badge badge-custom-blue">Activo</span>;
      case "RETIRADO":
        return <span className="badge bg-warning text-dark">Retirado</span>;
      case "DESERTADO":
        return <span className="badge bg-secondary">Desertado</span>;
      default:
        return <span className="badge bg-light text-dark">Desconocido</span>;
    }
  };
  