import React from 'react'
import config from "../../config";
//import 'animate.css';

import { useNavigate } from 'react-router-dom';
import { getToken, getSede } from '../../services/Usuario';

const Grado = ({index, grado, color, Periodo}) => {
  
  const navigate = useNavigate();
  const url_informe = config.API_URL_INFORMES;

  const colorProgres = (porc) => {
        if (porc < 25)
            return 'bg-danger';
        if (porc >= 25 && porc < 60)
            return 'bg-warning';
        if (porc > 60)
            return '';

  }  

  const irA = (url, externa=false) => {
    console.log(url)
    if (!externa)
        navigate(url);
    else
        window.open(url, "_blank");    
  }

  return (
    <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-12'>
    <div index={index} className="card-grado card activo-card customers-card animate__animated animate__fadeIn">       
        <div className="card-body ">    
        
              <div className='card-title d-flex justify-content-between' style={{height: '65px'}}>
                    <div className={'titulo_grado ' + (color ? color : '')}>{grado.nombre_grado} - {grado.num_grupo}</div>
                    <div>
                        <div className='num_grado'>{grado.num_stud}</div>
                        <div className='sub_titulo_grado'>Nº Estudiantes</div>
                    </div>
              </div>  
              <h4 className="cardGradoTitle">Detalles adicionales</h4>
              <ul className='list-group list-group-flush'>
                <li className='list-group-item d-flex align-items-center'><i className="bi bi-card-checklist texto_verde" style={{fontSize: '25px'}}></i> <span className='ms-2 texto_verde'>{grado.num_asig}</span> <span className="ms-2">No. Asignaturas</span></li>
                <div className='list-group-item d-flex flex-column align-items-start'>
                        <div className='d-flex align-items-center'>
                            <i className="bi bi-clipboard-check texto_verde" style={{fontSize: '25px'}}></i>
                            <span className='ms-2 texto_verde'>{grado.porc_notas}%</span> 
                            <span className="ms-2">Notas ingresadas</span>
                        </div>
                        <div className='progress'>
                            <div className={`progress-bar progress-bar-striped progress-bar-animated ${colorProgres(grado.porc_notas)}`} role="progressbar" style={{width: `${grado.porc_notas}%`}} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                <li className='list-group-item'>
                        {parseInt(Periodo) === 4 && 
                        <>
                            <div className='d-flex custom-align-center'>
                                <i className="bi bi-person-check texto_verde" style={{fontSize: '25px'}}></i>
                                <span className='ms-2 texto_verde' >{grado.num_aprobados}</span> 
                                <span className="ms-2 texto_verde" >Aprobados</span>
                            </div>
                       
                            <div className='d-flex  custom-align-center'>
                                <i className="bi bi-person-x texto_fuxia" style={{fontSize: '25px'}}></i><span className='ms-2 texto_fuxia'>{grado.num_reprobados}</span> 
                                <span className="ms-2">Reprobados</span>
                            </div>
                            </>
                         }
                        <div className=' d-flex  custom-align-center'>
                            <i className="bi bi-person-dash texto_fuxia" style={{fontSize: '25px'}}></i>
                            <span className='ms-2 texto_fuxia'>{grado.num_retirados}</span> <span className="ms-2">Retirados</span>                            
                        </div>
                        <div className=' d-flex  custom-align-center'>
                            <i className="bi bi-person-dash texto_fuxia" style={{fontSize: '25px'}}></i>
                            <span className='ms-2 texto_fuxia'>{grado.num_desert}</span> <span className="ms-2">Desertores</span>                            
                        </div>
                </li>                
                           
              </ul>
        </div>
        <div className='row justify-content-between'> 
            <div className='col-8 barra_card_buttom'>
                    {Periodo === '4' ? (
                        <button 
                            type="button" 
                            className="btn btn-outline-danger btn-sm px-2" 
                            onClick={() => irA(config.API_URL_INFORMES + '/informes-final.php?grado=' + grado.id_grado + '&grupo=' + Number(grado.id_grupo) + '&periodo=0&token=' + getToken() + '&db=' + getSede(), true)}
                        >
                            Informe FN
                        </button>
                    ) : (
                        <button 
                            type="button" 
                            className="btn btn-outline-danger btn-sm px-2" 
                            onClick={() => irA(config.API_URL_INFORMES + '/informes.php?grado=' + grado.id_grado + '&grupo=' + Number(grado.id_grupo) + '&periodo=' + Periodo + '&token=' + getToken() + '&db=' + getSede(), true)}
                        >
                            Informe
                        </button>
                    )}
                    <button type="button" className="btn btn-outline-danger btn-sm ml-2" onClick={()=>irA('/home/consolidado/'+grado.id_grado+'/'+grado.id_grupo)} >Consolidado</button>
            </div>
            
        <div className="col-2 filter-grados dropup " style={{button:'0px', right: '0px'}}>
                  <a className="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi bi-list-ul"></i></a>
                  <ul className="dropdown-menu dropdown-menu-end  dropdown-menu-up " >
                    <li><a className="dropdown-item" href="#" onClick={()=>irA('/home/listadoalumnos/'+grado.id_grado+'/'+Number(grado.id_grupo))}><i className="bi bi-list me-1 text-primary"></i>Listado Alumnos</a></li>
                    <li><a className="dropdown-item" href="#" onClick={()=>irA(url_informe + '/consolidados-final.php?grado='+grado.id_grado+'&grupo='+Number(grado.id_grupo)+'&periodo=1&token='+getToken()+'&db='+getSede(), true)}><i className="bi bi-layout-text-window-reverse me-1 text-primary"></i>Consolidado Final</a></li>
{/*                     <li><a className="dropdown-item" href="#" onClick={()=>irA(url_informe + '/certificado.php?grado='+grado.id_grado+'&grupo='+Number(grado.id_grupo)+'&periodo=1&token='+getToken()+'&db='+getSede(), true)}><i className="bi bi-file-earmark-break me-1 text-primary"></i>Certificados</a></li> */}
                    <li><a className="dropdown-item" href="#" onClick={()=>irA(url_informe + '/listado_alumnos_puestos.php?grado='+grado.id_grado+'&grupo='+Number(grado.id_grupo)+'&periodo=1&token='+getToken()+'&db='+getSede(), true)}><i className="bi bi-file-ruled me-1 text-primary"></i>Listado Puestos Final</a></li>
                  </ul>
                </div>
        </div>
    </div>
    </div>
  )
}

export default Grado