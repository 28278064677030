import React, { useEffect, useState } from 'react'
import MensajeError from '../components/MensajeError'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config';

const PageDatosInstitucion = () => {

   const [id_institucion, setId_institucion ] = useState(0) 
   const [nombre_inst, setNombre_Inst] = useState("") 
   const [sede, setSede] = useState("") 
   const [nit_inst, setNit_Inst] = useState("") 
   const [resolucion, setResolucion] = useState("") 
   const [coddane_inst, setCoddane_inst] = useState("") 
   const [codicfes_inst_man, setCodicfes_inst_man] = useState("") 
   const [codicfes_inst_tard, setCodicfes_inst_tard] = useState("") 
   const [codicfes_inst_noch, setCodicfes_inst_noch] = useState("") 
   const [telefonos_inst, setTelefonos_inst] = useState("") 
   const [direccion_inst, setDireccion_inst] = useState("") 
   const [correo_inst, setCorreo_inst] = useState("") 
   const [nom_recto_inst, setNom_recto_inst] = useState("") 
   const [indent_rector_inst, setIndent_rector_inst] = useState("") 
   const [rect_mun_indenti, setRect_mun_indenti] = useState("") 

   const [nom_secre_inst, setNom_secre_inst] = useState("") 
   const [indent_secre_inst, setIndent_secre_inst] = useState("") 
   const [secre_mun_indet, setSecre_mun_indet] = useState("") 

   useEffect(() => {
    GetData('institucion/datosinstitucion', null).then ((result) =>{
      if (result.error === false){
          const empresa = result.datos[0];
          console.log(empresa)
          setId_institucion(empresa.id_institucion)
          setNombre_Inst(empresa.nombre_inst);
          setSede(empresa.sede);
          setNit_Inst(empresa.nit_inst);
          setResolucion(empresa.resolucion);
          setCoddane_inst(empresa.coddane_inst);
          setCodicfes_inst_man(empresa.codicfes_inst_man);
          setCodicfes_inst_tard(empresa.codicfes_inst_tard);
          setCodicfes_inst_noch(empresa.codicfes_inst_noch);
          setTelefonos_inst(empresa.telefonos_inst);
          setDireccion_inst(empresa.direccion_inst);
          setCorreo_inst(empresa.correo_inst);

          setNom_recto_inst(empresa.nom_recto_inst);
          setIndent_rector_inst(empresa.indent_rector_inst);
          setRect_mun_indenti(empresa.rect_mun_indenti);

          setNom_secre_inst(empresa.nom_secre_inst);
          setIndent_secre_inst(empresa.indent_secre_inst);
          setSecre_mun_indet(empresa.secre_mun_indet);
      }
  })
    
  }, [])

   const Guardar = () => {
      const data = {
        id_institucion,
        nombre_inst,
        sede,
        nit_inst,
        resolucion,
        coddane_inst,
        telefonos_inst,
        direccion_inst,
        codicfes_inst_man,
        codicfes_inst_tard,
        codicfes_inst_noch,
        correo_inst,
        nom_recto_inst,
        indent_rector_inst,
        rect_mun_indenti,
        nom_secre_inst,
        indent_secre_inst,
        secre_mun_indet

      }
      PostData('institucion/save', data).then((result) => {
          if (result.error === false){
            Swal.fire({
              title: 'Guardar Empresa',
              text: result.mensaje,
              icon: 'success',
              confirmButtonText: 'Aceptar'
            });
            window.location.reload();
          }else{
            Swal.fire({
              title: 'Guardar Empresa',
              text: result.mensaje,
              icon: 'error',
              confirmButtonText: 'Aceptar'
            });
          }
      })
   }

  
  return (
    <>
       <section className='section dashboard'>
            <div className="card pb-2">
            <div className='card-header'>Datos de la Institución Educativa</div>    
            <div className="card-body pb-0">

                <div className="formulario">
                    <form method="post" >

                    <div className='row'>
                     <div className="col-md-2 d-flex justify-content-center align-items-center">
                        <img src={config.LOGO} className="mt-3" width={100} />
                      </div>
                        <div className='col-md-10'>

                              <div className='col-md-8'>
                                      <span className='label'>Nombre de la Institución: </span> {!nombre_inst && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={nombre_inst} onChange={(e) => setNombre_Inst(e.target.value)} />
                              </div>   
                              <div className='col-md-6'>
                                      <span className='label'>Sede: </span> {!sede && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={sede} onChange={(e) => setSede(e.target.value)} />
                              </div>   
                          
                          
                              <div className='col-md-6'>
                                      <span className='label'>Nit : </span> {!nit_inst && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={nit_inst} onChange={(e) => setNit_Inst(e.target.value)} />
                              </div>     
                              <div className='col-md-6'>
                                      <span className='label'>Resolución : </span> {!resolucion && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={resolucion} onChange={(e) => setResolucion(e.target.value)} />
                              </div>     
                              <div className='col-md-6'>
                                      <span className='label'>Código DANE : </span> {!coddane_inst && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={coddane_inst} onChange={(e) => setCoddane_inst(e.target.value)} />
                              </div>     
                              <div className='col-md-6'>
                                      <span className='label'>Código ICFES Mañana : </span> {!codicfes_inst_man && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={codicfes_inst_man} onChange={(e) => setCodicfes_inst_man(e.target.value)} />
                              </div>     
                              <div className='col-md-6'>
                                      <span className='label'>Código ICFES Tarde : </span> {!codicfes_inst_tard && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={codicfes_inst_tard} onChange={(e) => setCodicfes_inst_tard(e.target.value)} />
                              </div>     
                              <div className='col-md-6'>
                                      <span className='label'>Código ICFES Noche : </span> {!codicfes_inst_noch && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={codicfes_inst_noch} onChange={(e) => setCodicfes_inst_noch(e.target.value)} />
                              </div>     
                              <div className='col-md-6'>
                                      <span className='label'>Teléfono: </span> {!telefonos_inst && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={telefonos_inst} onChange={(e) => setTelefonos_inst(e.target.value)} />
                              </div>     
                              <div className='col-md-8'>
                                      <span className='label'>Dirección: </span> {!direccion_inst && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={direccion_inst} onChange={(e) => setDireccion_inst(e.target.value)} />
                              </div>     
                              <div className='col-md-8'>
                                      <span className='label'>Correo electrónico: </span> {!correo_inst && MensajeError('Debe llenar el campo')}
                                      <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={correo_inst} onChange={(e) => setCorreo_inst(e.target.value)} />
                              </div>     
                              <div className='row'>
                                    <div className='col-md-6'>
                                            <span className='label'>Nombre Completo Rector: </span>
                                            <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={nom_recto_inst} onChange={(e) => setNom_recto_inst(e.target.value)} />
                                    </div>     
                                    <div className='col-md-3'>
                                            <span className='label'>Identifiación Rector: </span>
                                            <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={indent_rector_inst} onChange={(e) => setIndent_rector_inst(e.target.value)} />
                                    </div>     
                                    <div className='col-md-3'>
                                            <span className='label'>Municipio Rector: </span>
                                            <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={rect_mun_indenti} onChange={(e) => setRect_mun_indenti(e.target.value)} />
                                    </div>     
                              </div>

                              <div className='row'>
                                    <div className='col-md-6'>
                                            <span className='label'>Nombre Completo Secretaria: </span>
                                            <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={nom_secre_inst} onChange={(e) => setNom_secre_inst(e.target.value)} />
                                    </div>     
                                    <div className='col-md-3'>
                                            <span className='label'>Identifiación Secretaria: </span>
                                            <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={indent_secre_inst} onChange={(e) => setIndent_secre_inst(e.target.value)} />
                                    </div>     
                                    <div className='col-md-3'>
                                            <span className='label'>Municipio Secretaria: </span>
                                            <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={secre_mun_indet} onChange={(e) => setSecre_mun_indet(e.target.value)} />
                                    </div>     
                              </div>

                              <div className='col-md-12 mt-3'>
                                  <button type="button" className="btn btn-primary btn-sm" onClick={Guardar} ><i className="bi bi-clipboard-plus"></i> Guardar</button>  
                              </div>
                             

                        </div> {/* fin col-md-9 */}
                    
                    

                   
                    </div>
              


                    </form>
                </div>
            </div>
       </div>
       </section>             
       <ToastContainer />
    </>
  )
}

export default PageDatosInstitucion