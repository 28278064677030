import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import BotonTabla from '../components/BotonTabla'
import Swal from 'sweetalert2'
import { PostData } from '../services/PostData'
import {validarEmail} from '../components/utils'
import { useParams } from 'react-router-dom'
import { getSede, getToken } from '../services/Usuario'
import config from '../config'
import { getBadge } from '../components/getBadge'

const ListaAlumnos = () => {   
   
   const { grado, grupo } = useParams();
   const [ListaTiposUS, setListaTiposUS] = useState([]) 
   const [TiposUsuario, setTiposUsuario] = useState('Todos') 
   const [ListadoAlumnos, setListadoAlumnos] = useState([]) 
   const [Loading, setLoading] = useState(true)
   const [NombreGrado, setNombreGrado] = useState("")

   const [LoadingForm, setLoadingForm] = useState(false)
   const [ModIdMatricula, setModIdMatricula] = useState(0)
   const [EstadoMat, setEstadoMat] = useState(null)
   const [Nombres, setNombres] = useState("")  
   const [Telefono, setTelefono] = useState("")  
   const [Direccion, setDireccion] = useState("")  
   const [TipoIden, setTipoIden] = useState("")  
   const [Identificacion, setIdentificacion] = useState("")  
   
   const Url_Informes = config.API_URL_INFORMES;

   useEffect(() => {
        const datos = {
            id_grado: grado,
            id_grupo: grupo  
        }
        setLoading(true)
        PostData('matriculas/alumnosgradoadmin', datos).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setListadoAlumnos(result.alumnos)
                setNombreGrado(result.grado)
                setLoading(false)
            }
        })

    }, []) 

    const VerDatos = (dato) => {
        
                setModIdMatricula(dato.id_matricula)
                setNombres(dato.nombres_apellidos_al)
                setTelefono(dato.telefono_al)
                setDireccion(dato.direccion_al)
                setEstadoMat(dato.id_estado_matricula)
         

        const modal = document.getElementById('modalD');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
          // Usar el método modal() para abrir el modal
          const modalInstancia = new window.bootstrap.Modal(modal);
          modalInstancia.show();
        }
    }

    const AbrirInforme = (op) => {
        const url_informe = config.API_URL_INFORMES;
        let url = "";
        const grado = parseInt(op.cod_grado);
        
        if (grado < 10) {
            url = url_informe + '/certificado_tc_1_5.php?idmatricula=' + op.id_matricula + '&grado=' + grado + '&grupo=' + grupo + '&token=' + getToken() + '&db=' + getSede();
        } else if (grado >= 10 && grado < 14) {
            url = url_informe + '/certificado_tc_6_9.php?idmatricula=' + op.id_matricula + '&grado=' + grado + '&grupo=' + grupo + '&token=' + getToken() + '&db=' + getSede();
        } else {
            url = url_informe + '/certificado_tc_10_11.php?idmatricula=' + op.id_matricula + '&grado=' + grado + '&grupo=' + grupo + '&token=' + getToken() + '&db=' + getSede();
        }
        
      
        // Establecer el src del iframe
        const iframe = document.getElementById("iframePdf");
        iframe.src = url;
      
        const modal = document.getElementById('modalPdf');

        const modalInstancia = new window.bootstrap.Modal(modal);
        modalInstancia.show();

      };

      const AbrirInformeTodos = () => {
        const url_informe = config.API_URL_INFORMES;
        let url = "";
        
        if (grado < 10) {
            url = url_informe + '/certificado_tc_1_5.php?grado=' + grado + '&grupo=' + grupo + '&token=' + getToken() + '&db=' + getSede();
        } else if (grado >= 10 && grado < 14) {
            url = url_informe + '/certificado_tc_6_9.php?grado=' + grado + '&grupo=' + grupo + '&token=' + getToken() + '&db=' + getSede();
        } else {
            url = url_informe + '/certificado_tc_10_11.php?grado=' + grado + '&grupo=' + grupo + '&token=' + getToken() + '&db=' + getSede();
        }
        
      
        // Establecer el src del iframe
        const iframe = document.getElementById("iframePdf");
        iframe.src = url;
      
        const modal = document.getElementById('modalPdf');

        const modalInstancia = new window.bootstrap.Modal(modal);
        modalInstancia.show();

      };
      


  return (
    <>
        <section className='section dashboard'>
            <div class="card">
                  
                <div className="card-body pb-0">
                    <h5 className="card-title">Listado Alumnos   
                    </h5>

                    <div className='row'>
                             <div className='col-md-5' style={{marginTop: '5px'}}>
                                <span className='ml-4 label'>Grado:</span> <span className='texto'>{NombreGrado} - 0{grupo}</span>  
                                <span className='ml-4 label'>N° Alumnos: </span> <span className='texto'>{ListadoAlumnos.length}</span>                                
                            </div>
                            <div className='col-md-5'>
                                <div className="btn-group mt-3" role="group">
                                    <button id="btnGroupDrop1" type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        Seleccione el Informe
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                        <li><a className="dropdown-item" target="_blank"  href={Url_Informes + `listado_estados_matr_final.php?grado=${grado}&grupo=${grupo}&token=${getToken()}&db=${getSede()}`}><i class="bi bi-file-earmark-pdf"></i> Listado Estados Final</a></li>
                                        <li><a className="dropdown-item" target="_blank"  href={Url_Informes + `listado_estados_matr_final_xls.php?grado=${grado}&grupo=${grupo}&token=${getToken()}&db=${getSede()}`}><i class="bi bi-file-ruled"></i> Listado Estados Final Excel</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <button type="button" className="btn btn-danger" onClick={() => AbrirInformeTodos()}>
                                    Todos Certificado
                                </button>
                            </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-md-12'>
                                <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                Los informes Finales y el estado final dependen del informe final de calificaciones, debe primero visualizar el informe para poder tener los datos del Estado Final de la matricula.
                                </div>
                           </div>
                    </div>
                    {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <table class="table table-striped  mt-2">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col"> Nombre Alumno</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Dirección</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Est. Final</th>
                            <th scope="col">Op</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoAlumnos.map((us, index) => 
                        <tr index={us.id_matricula}>
                            <th scope="row">{index+1}</th>
                            <td> <a href='#' onClick={()=> VerDatos(us)} title='Ver Alumno' >{us.nombres_apellidos_al}</a></td>
                            <td>{us.telefono_al}</td>
                            <td>{us.direccion_al}</td>
                            <td>{us.id_estado_matricula === "1" ? <span class="badge bg-success">Activo</span> : <span class="badge bg-secondary">Inactivo</span>}</td>
                            <td>
                                {us.id_estado_matricula === "1" ? (
                                    <>
                                    {getBadge(us.estado_academico)}
                                    </>
                                ) : (
                                    <>
                                    {getBadge(us.nombre_estado)}
                                    </>
                                )}
                                </td>
                            <td>
                                {us.estado_academico === 'APROB\u00d3' &&
                                    <BotonTabla icono='certificado.png' titulo='Ver Certificado' evento={()=>AbrirInforme(us)} />
                                }
                            
                            </td>
                        </tr>
                        )}    
                        
                        </tbody>
                    </table>
               </div>
            </div>
        </section>            

        <div className="modal fade" id="modalD" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-pencil-square"></i> Ver Datos ALumno {ModIdMatricula}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {LoadingForm && 
                        <div className="progress mt-3">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }     
                        <div className='alert alert-warning alert-dismissible fade show'>
                            Si no desea cambiar la contraseña, por favor deje los campos 'Contraseña' y 'Confirmar contraseña' en blanco.    
                        </div>
                        <div className='row mt-2'>
                           
                            <div className='col-md-12'>
                                <span className='label'>Nombres y Apellidos: </span>
                                <input type="text" 
                                    className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Nombres} disabled />
                            </div>     
                            <div className='col-md-6'>
                                <span className='label'>Teléfono: </span>
                                <input type="text" 
                                    className="form-control form-control-sm estilocampo" id="nombres" name="telefono" value={Telefono} disabled />
                            </div>     
                            <div className='col-md-6'>
                                <span className='label'>Dirección: </span>
                                <input type="text" 
                                    className="form-control form-control-sm estilocampo" id="nombres" name="direccion" value={Direccion} disabled />
                            </div>     
                            
                        </div>
                      </div>
                        <div className="modal-footer">
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-door-closed"></i> Cerrar</button>
                                <button type="button" className="btn btn-primary btn-sm" onClick=""  data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Guardar Usuario"><i className="bi bi-clipboard-plus"></i> Guardar</button>                      
                         </div>
                 </div>
            </div>
        </div>                        


        <div className="modal fade" id="modalPdf" tabIndex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-xl"> {/* Modal maximizado */}
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                        <i className="bi bi-file-earmark-pdf"></i> Certificado
                        </h5>
                        <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body" style={{ height: "80vh" }}> {/* Espacio para iframe */}
                        <iframe
                        id="iframePdf"
                        src=""
                        title="Certificado PDF"
                        style={{ width: "100%", height: "100%", border: "none" }}
                        ></iframe>
                    </div>
                    </div>
                </div>
                </div>
    </>
  )
}

export default ListaAlumnos